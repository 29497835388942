/* stylelint-disable scss/dollar-variable-pattern */
@use 'sass:map';
@use '@angular/material' as mat;

$_palettes: (
  primary: (
    0: #000,
    10: #22005d,
    20: #3a0092,
    25: #460da7,
    30: #5223b3,
    35: #5e33bf,
    40: #6a41cc,
    50: #845de7,
    60: #9e79ff,
    70: #b69bff,
    80: #cfbcff,
    90: #e9ddff,
    95: #1a0c2a,
    98: #fdf7ff,
    99: #fffbff,
    100: #fff,
  ),
  secondary: (
    0: #000,
    10: #012200,
    20: #033900,
    25: #044600,
    30: #055300,
    35: #076000,
    40: #096e00,
    50: #0e8b00,
    60: #12a800,
    70: #3bc428,
    80: #5be144,
    90: #78ff5e,
    95: #cbffb7,
    98: #edffe1,
    99: #f7ffee,
    100: #fff,
  ),
  tertiary: (
    0: #000,
    10: #3d0600,
    20: #630e00,
    25: #771300,
    30: #8c1800,
    35: #a11d00,
    40: #b72300,
    50: #e33005,
    60: #ff5632,
    70: #ff8a70,
    80: #ffb4a4,
    90: #ffdad3,
    95: #ffede9,
    98: #fff8f6,
    99: #fffbff,
    100: #fff,
  ),
  neutral: (
    0: #000,
    4: #000,
    6: #121017,
    10: #1d1a22,
    12: #1d1a22,
    17: #28242d,
    20: #322f37,
    22: #322f37,
    24: #322f37,
    25: #3e3a43,
    30: #49454e,
    35: #55515a,
    40: #615d66,
    50: #7a757f,
    60: #948f99,
    70: #afa9b4,
    80: #cbc4cf,
    87: #cbc4cf,
    90: #e7e0eb,
    92: #e7e0eb,
    94: #e7e0eb,
    95: #f6eefa,
    96: #f6eefa,
    98: #fef7ff,
    99: #fffbff,
    100: #fff,
  ),
  neutral-variant: (
    0: #000,
    10: #1d1a22,
    20: #322f38,
    25: #3d3a43,
    30: #49454e,
    35: #54515a,
    40: #615d66,
    50: #7a757f,
    60: #948f99,
    70: #afa9b4,
    80: #cac4cf,
    90: #e7e0eb,
    95: #f5eefa,
    98: #fdf7ff,
    99: #fffbff,
    100: #fff,
  ),
  error: (
    0: #000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #fff,
  ),
);
$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);
$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    typography: (
      brand-family: 'Source Sans 3',
      bold-weight: 700,
      medium-weight: 600,
      regular-weight: 400,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    typography: (
      brand-family: 'Source Sans 3',
      bold-weight: 700,
      medium-weight: 600,
      regular-weight: 400,
    ),
  )
);
