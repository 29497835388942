/* stylelint-disable scss/dollar-variable-pattern */
@use 'sass:map';
@use '@angular/material' as mat;

$_palettes: (
  primary: (
    0: #000,
    10: #210452,
    20: #35127a,
    25: #351399,
    30: #451f99,
    35: #451f99,
    40: #5e33bf,
    50: #7548db,
    60: #9163f7,
    70: #a378ff,
    80: #c0a1ff,
    90: #e0ccff,
    95: #f6edff,
    98: #fdfafe,
    99: #fdfaff,
    100: #fff,
  ),
  secondary: (
    0: #000,
    10: #0f1014,
    20: #212129,
    25: #044600,
    30: #2f303b,
    35: #2f303f,
    40: #41424f,
    50: #585969,
    60: #717385,
    70: #8b8d9e,
    80: #a8a9ba,
    90: #c2c4d1,
    95: #e6e7f0,
    98: #fafbff,
    99: #fafbff,
    100: #fff,
  ),
  tertiary: (
    0: #000,
    10: #292b17,
    20: #40422b,
    25: #53573e,
    30: #53573e,
    35: #a11d00,
    40: #6b7053,
    50: #868c69,
    60: #9fa67e,
    70: #bac295,
    80: #d1d6a7,
    90: #e3e8ba,
    95: #f5f7d7,
    98: #fafaf5,
    99: #fafaf5,
    100: #fff,
  ),
  neutral: (
    0: #000,
    4: #0d0d0d,
    6: #171717,
    10: #212121,
    12: #262626,
    17: #2e2e2e,
    20: #363636,
    22: #3b3b3b,
    24: #404040,
    25: #404040,
    30: #4d4d4d,
    40: #666,
    50: #808080,
    60: #999,
    70: #b3b3b3,
    80: #cfcfcf,
    87: #dedede,
    90: #e6e6e6,
    92: #ebebeb,
    94: #f0f0f0,
    95: #f2f2f2,
    96: #f5f5f5,
    98: #fafafa,
    99: #fcfcfc,
    100: #fff,
  ),
  neutral-variant: (
    0: #000,
    10: #222,
    20: #373737,
    25: #373737,
    30: #4f4f4f,
    35: #4f4f4f,
    40: #666,
    50: #7e7e7e,
    60: #999,
    70: #b4b4b4,
    80: #d0d0d0,
    90: #ececec,
    95: #fafafa,
    98: #fcfcfc,
    99: #fcfcfc,
    100: #fff,
  ),
  error: (
    0: #000,
    10: #410b0d,
    20: #631618,
    25: #631618,
    30: #962428,
    35: #962428,
    40: #c43136,
    50: #de3e43,
    60: #e56367,
    70: #ed8e91,
    80: #f2c2c2,
    90: #fadcdc,
    95: #ffeded,
    98: #fffafa,
    99: #fffafa,
    100: #fff,
  ),
);
$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);
$avalon-light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
  )
);
$avalon-dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
  )
);
