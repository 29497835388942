@use '@angular/material' as mat;
@use '../shared/clarivate-typography' as clv;

@mixin typography($typography-config) {
  @include clv.clarivate-font-faces; // this should go someplace global (like a CDN)

  .cdx-footer {
    @include clv.clarivate-regular(14px);

    &__copyright {
      @include clv.clarivate-bold(16px);
    }

    &__group-title {
      @include clv.clarivate-bold(16px);
    }
  }
}

@mixin theme($theme) {
  @include mat.typography-hierarchy($theme, $back-compat: true);
  @if $theme {
    @include typography($theme);
  }
}
