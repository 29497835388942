@use '@angular/material' as mat;
@use '../shared/clarivate-typography' as clv;

@mixin typography($typography-config) {
  @include clv.clarivate-font-faces; // this should go someplace global (like a CDN)

  .cdx-header {
    @include mat.typography-hierarchy($typography-config, body-1);

    &__product-name-or-logo {
      @include clv.clarivate-bold(26px); // aligns with "headline"
    }
  }
}

@mixin theme($theme) {
  @include mat.typography-hierarchy($theme, $back-compat: true);
  @if $theme {
    @include typography($theme);
  }
}
