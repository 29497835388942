/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@use '@cdx/theme-angular-material' as cdx;
@use '@cdx/ngx-branding/header/theme' as header;
@use '@cdx/ngx-branding/footer/theme' as footer;

@include cdx.default;
@include header.theme(cdx.$cdx-theme);
@include footer.theme(cdx.$cdx-theme);

@include mat.all-component-typographies();
@include mat.core();

body {
  @include cdx.theme(cdx.$cdx-theme);
  @include cdx.m2-compat;
}

.dark-parts {
  @include mat.all-component-themes(cdx.$cdx-dark-theme);
  color: white !important;
  .mdc-label {
    color: white !important;
  }
  h5 {
    font-weight: bold;
    margin-top: 16px;
  }
}

.mat-sidenav-container {
  height: calc(100vh - 5.5rem);
}

.mat-sidenav {
  width: 240px !important;
  background-color: #212121 !important;;
  padding: 20px;
  border-radius: 0 !important;

  .mat-mdc-checkbox {
    display: block;
  }
}

.main-menu {
  flex: 4;
  .mat-mdc-tab-links {
    width: 408px !important;
  }
}

.admin-menu {
  justify-self: end;
  .mat-mdc-tab-links {
    width: 260px !important;
  }
}
.mat-mdc-tab-link-container {
  border: 0 none !important;
}
.mat-sidenav-content {
  overflow: auto;
}

body {
  margin: 0;
}

/* START: get rid of default footer */
.footer {
  display: none;
}

.side-menu {
  top: 5.5rem !important;
  height: calc(100vh) !important;
}

/* END: get rid of default footer */

/**** Material ****/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .mat-mdc-cell,
  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    min-height: 0; // Reset min-height.
  }
}

// @cpa/cpa-ui/scss/cpa-ui import is overriding some @angular/material styles and then error messages get the wrong font-size
.mat-mdc-form-field-error {
  font-size: 75%;
}

/* Hide some material theme colors and animations */
.mat-mdc-form-field:not(.mat-form-field-invalid) {
   .mat-form-field-underline .mat-form-field-ripple {
    display: none;
  }

  &.mat-focused .mat-form-field-label {
    color: rgba(0, 0, 0, .54);
  }
}

// PendingChanges guard:
//  - Requires special z-index in order to be displayed on top the CPA spinner before navigation is approved or rejected
.modal-open #pending-changes-modal {
  .modal-backdrop, .modal {
    z-index: 11000;
  }
}

.p-checkbox-box {
  position: relative;
}

.mr-2 {
  margin: 8px;
}

/* AOktay20240928 small fixes */
mat-sidenav {
  .mat-mdc-list-base {
    padding-top: 0 !important;
  }

  .mat-expansion-panel-header {
    padding: 0 12px !important;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.form-group {
  margin-bottom: -3px !important;
}

.mat-mdc-dialog-content {
  overflow: unset !important
}

.mat-mdc-table,
.mat-mdc-row,
.mat-mdc-cell,
.mat-mdc-paginator {
  background-color: white;
}

.mat-mdc-cell {
  border-color: #0000001f !important;
}
