@use '@angular/material' as mat;

@use '../../colors' as colors;
@use './overrides';
@use './theme/default.scss' as default;
@use './theme/cdx.m2.scss' as cdx-m2;

@mixin default($theme: default.$light-theme) {
  @include mat.core;

  html {
    @include mat.all-component-themes($theme);
    @include overrides.material-overrides;
    @include focus-indicators-color(colors.$primary-500);
  }
}

@mixin m2-compat() {
  @include mat.typography-hierarchy(cdx-m2.$cdx-typography);
  @include mat.color-variants-backwards-compatibility(default.$light-theme);
}

@mixin theme($theme) {
  @include mat.core-color($theme);
  @include mat.all-component-colors($theme);
  @include mat.all-component-densities($theme);
  @include mat.all-component-typographies($theme);
}

@mixin focus-indicators-color($color, $transparent-background: false) {
  .cdk-keyboard-focused,
  .cdx-focused {
    @include mat.strong-focus-indicators;
    @include mat.strong-focus-indicators-theme($color);

    @if $transparent-background {
      --mat-text-button-focus-state-layer-opacity: 0;
      --mat-tab-header-active-ripple-color: transparent;

      span.mat-mdc-button-persistent-ripple {
        --mat-outlined-button-state-layer-color: transparent;
      }
    }
  }

  // change the color of the user-agent keyboard focus state for anchors
  a:focus-visible:not(.mat-button-base) {
    outline-color: $color;
  }
}
