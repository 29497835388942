@forward 'theme/blue';
@forward 'theme/teal';

// Primary
$primary-50: #eee7f8;
$primary-100: #d2c4ed;
$primary-200: #b49de2;
$primary-300: #9675d7;
$primary-400: #7f56ce;
$primary-500: #5d33bf;
$primary-600: #4f2bb6;
$primary-700: #4125af;
$primary-800: #2818a2;
$primary-900: #23018e;

// Primary Dark
$primary-dark-50: #f7f0ff;
$primary-dark-100: #eadaff;
$primary-dark-200: #ddc2ff;
$primary-dark-300: #cfa9ff;
$primary-dark-400: #c496ff;
$primary-dark-500: #ba84ff;
$primary-dark-600: #b37cff;
$primary-dark-700: #ab71ff;
$primary-dark-800: #a367ff;
$primary-dark-900: #9454ff;

// Neutral Palette
$neutral-50: #fafafa;
$neutral-100: #f5f5f5;
$neutral-200: #eee;
$neutral-300: #e0e0e0;
$neutral-400: #bdbdbd;
$neutral-500: #9e9e9e;
$neutral-600: #757575;
$neutral-700: #616161;
$neutral-800: #424242;
$neutral-900: #212121;

// Accent Palette
$accent-50: #e5f5e4;
$accent-100: #c2e5bc;
$accent-200: #9ad490;
$accent-300: #6ec462;
$accent-400: #49b83d;
$accent-500: #16ab03;
$accent-600: #019c00;
$accent-700: #008b00;
$accent-800: #007a00;
$accent-900: #005b00;

// Warn Palette
$warn-50: #fcece8;
$warn-100: #f6c5b9;
$warn-200: #f09f8b;
$warn-300: #e9795d;
$warn-400: #e3522e;
$warn-500: #dd2c00;
$warn-600: #c92800;
$warn-700: #b52400;
$warn-800: #a12000;
$warn-900: #8d1c00;
