@mixin material-overrides() {
  // stroked buttons (that are not disabled) should inherit border color
  .mat-stroked-button:not(.mat-button-disabled) {
    border-color: inherit;
  }

  .mat-menu-panel {
    max-width: none !important;
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: none !important;
  }

  .mat-tab-labels {
    .mat-tab-label-active {
      opacity: 1;
    }
  }

  .mat-form-field--transparent {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;

      .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
      }
    }
  }

  .mat-typography.mat-legacy-spacing {
    p {
      margin-block-end: 1em;
      margin-block-start: 1em;
    }
  }
}
