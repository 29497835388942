/* stylelint-disable scss/dollar-variable-pattern */
@use 'sass:map';
@use '@angular/material' as mat;

$_palettes: (
  primary: (
    0: #0f1011,
    10: #191a1c,
    20: #1f2022,
    25: #1f2022,
    30: #252628,
    35: #252628,
    40: #2a2b2d,
    50: #4a4b4d,
    60: #6a6b6c,
    70: #959596,
    80: #bfbfc0,
    90: #e5e6e6,
    95: #f2f2f2,
    98: #f8f8f8,
    99: #fcfcfc,
    100: #fff,
  ),
  secondary: (
    0: #fff,
    10: #fcfcfc,
    20: #f8f8f8,
    25: #f2f2f2,
    30: #e5e6e6,
    35: #bfbfc0,
    40: #959596,
    50: #6a6b6c,
    60: #4a4b4d,
    70: #2a2b2d,
    80: #252628,
    90: #252628,
    95: #1f2022,
    98: #1f2022,
    99: #191a1c,
    100: #0f1011,
  ),
  tertiary: (
    0: #000,
    10: #22005d,
    20: #3a0092,
    25: #460da7,
    30: #5223b3,
    35: #5e33bf,
    40: #6a41cc,
    50: #845de7,
    60: #9e79ff,
    70: #b69bff,
    80: #cfbcff,
    90: #e9ddff,
    95: #e9ddff,
    98: #fdf7ff,
    99: #fffbff,
    100: #fff,
  ),
  neutral: (
    0: #000,
    4: #0d0d0d,
    6: #171717,
    10: #212121,
    12: #262626,
    17: #2e2e2e,
    20: #363636,
    22: #3b3b3b,
    24: #404040,
    25: #404040,
    30: #4d4d4d,
    40: #666,
    50: #808080,
    60: #999,
    70: #b3b3b3,
    80: #cfcfcf,
    87: #dedede,
    90: #e6e6e6,
    92: #ebebeb,
    94: #f0f0f0,
    95: #f2f2f2,
    96: #f5f5f5,
    98: #fafafa,
    99: #fcfcfc,
    100: #fff,
  ),
  neutral-variant: (
    0: #000,
    10: #222,
    20: #373737,
    25: #373737,
    30: #4f4f4f,
    35: #4f4f4f,
    40: #666,
    50: #7e7e7e,
    60: #999,
    70: #b4b4b4,
    80: #d0d0d0,
    90: #ececec,
    95: #fafafa,
    98: #fcfcfc,
    99: #fcfcfc,
    100: #fff,
  ),
  error: (
    0: #000,
    10: #410b0d,
    20: #631618,
    25: #631618,
    30: #962428,
    35: #962428,
    40: #c43136,
    50: #de3e43,
    60: #e56367,
    70: #ed8e91,
    80: #f2c2c2,
    90: #fadcdc,
    95: #ffeded,
    98: #fffafa,
    99: #fffafa,
    100: #fff,
  ),
);
$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);
$helix-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    typography: (
      brand-family: 'Source Sans 3',
      bold-weight: 600,
      medium-weight: 500,
      regular-weight: 400,
    ),
  )
);
$helix-dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
  )
);

@mixin theme-helix-overrides() {
  --mdc-protected-button-container-shape: 2px;
  --mdc-outlined-button-container-shape: 2px;
  --mdc-filled-button-container-shape: 2px;
  --mdc-text-button-container-shape: 2px;
  --mdc-filled-text-field-container-shape: 2px;
  --mat-standard-button-toggle-shape: 2px;
  --mdc-filled-button-container-height: 44px;
  --mdc-outlined-button-container-height: 44px;
  --mdc-protected-button-container-height: 44px;
  --mdc-text-button-container-height: 44px;
  --mdc-tab-indicator-active-indicator-height: 4px;
  --mdc-shape-small: 0;
  --mat-autocomplete-background-color: white;
  --mat-select-panel-background-color: white;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-menu-container-color: white;
  /* stylelint-disable-next-line scss/operator-no-newline-after */
  --mat-datepicker-calendar-container-elevation-shadow: 0 3px 1px -2px rgb(0 0 0 /
          20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  --mat-divider-color: #dfe1e2;
  --mdc-linear-progress-track-color: #cfcfcf;
  --mdc-chip-container-shape-radius: 16px;

  .mdc-text-field {
    border-radius: 0 !important;

    --mdc-shape-small: 0;
  }

  [fitInkBarToContent] {
    --mdc-tab-indicator-active-indicator-shape: 2px 2px 0 0;
  }

  .hlx-accent-button {
    @include mat.button-color($helix-theme, $color-variant: tertiary);
  }

  .hlx-negative-button {
    @include mat.button-color($helix-theme, $color-variant: error);
  }

  .hlx-invert-button {
    @include mat.button-color($helix-theme, $color-variant: secondary);
  }

  .hlx-btn-small {
    button.mdc-button {
      padding: 6px 16px;
      font-size: 14px;
    }
  }

  .hlx-btn-x-small {
    button.mdc-button {
      padding: 4px 12px;
      font-size: 12px;
    }
  }

  .hlx-btn-large {
    button.mdc-button {
      height: auto;
      padding: 18px 32px;
    }
  }

  .hlx-neutral-chip {
    @include mat.chips-color($helix-theme, $color-variant: secondary);

    --mdc-chip-elevated-container-color: #dfe1e2;
  }

  .hlx-primary-chip {
    @include mat.chips-color($helix-theme, $color-variant: primary);

    --mdc-chip-elevated-container-color: #2a2b2d;
    --mdc-chip-label-text-color: #fff;
  }

  .hlx-accent-chip {
    @include mat.chips-color($helix-theme, $color-variant: tertiary);

    --mdc-chip-elevated-container-color: #5e33bf;
    --mdc-chip-label-text-color: #fff;
  }

  .hlx-negative-chip {
    @include mat.chips-color($helix-theme, $color-variant: error);

    --mdc-chip-elevated-container-color: #fadcdc;
    --mdc-chip-label-text-color: #410b0d;
  }

  .hlx-warn-chip {
    @include mat.chips-color($helix-theme, $color-variant: error);

    --mdc-chip-elevated-container-color: #ffefd1;
    --mdc-chip-label-text-color: #402b00;
  }

  .hlx-positive-chip {
    @include mat.chips-color($helix-theme, $color-variant: error);

    --mdc-chip-elevated-container-color: #d2f7d6;
    --mdc-chip-label-text-color: #003600;
  }

  .hlx-info-chip {
    @include mat.chips-color($helix-theme, $color-variant: error);

    --mdc-chip-elevated-container-color: #d7e8f7;
    --mdc-chip-label-text-color: #031c40;
  }
}
